html{
  scroll-behavior: smooth;
}

body{ 
  margin: 0;
  z-index: -1;
  background-size:cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}



*{
  box-sizing:border-box
}


a{
  color: #f9f9f9;
  text-decoration:none;
}


@media only screen and (min-width:768px){
  body{
    font-size: 16px;
  }
}


@media only screen and (min-width:480px) and (max-width:768px){
  body{
    font-size: 15px;
  }
}


@media only screen and (max-width:479px){
  body{
    font-size: 14px;
  }
}